import { ScreenerLocationStatus } from "@/types/screeners";

enum Rating {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

const badgeColors: {
  [key: string]: {
    [key: string]: {
      background: string;
      text: string;
    };
  };
} = {
  qualifyingPassRate: {
    high: { background: "#b9e7df", text: "#0f6b5c" },
    low: { background: "#f5c9c7", text: "#922317" },
  },
  overallFit: {
    [Rating.High]: { background: "#b9e7df", text: "#0f6b5c" },
    [Rating.Medium]: { background: "#fde68a", text: "#e3573d" },
    [Rating.Low]: { background: "#f5c9c7", text: "#922317" },
  },
  competency: {
    [Rating.High]: { background: "#139F88", text: "#139F88" },
    [Rating.Medium]: { background: "#e3573d", text: "#e3573d" },
    [Rating.Low]: { background: "#F04250", text: "#F04250" },
  },
  isVisibleOnPdf: {
    default: {
      background: "#E6E6E6",
      text: "#666666",
    },
  },
  status: {
    [ScreenerLocationStatus.Live]: { background: "#B8ECE3", text: "#0F7C6A" },
    [ScreenerLocationStatus.Paused]: { background: "#F2F2F2", text: "#999999" },
  },
};

export const getBadgeColor = (value: string, type: string) => {
  if (type === "qualifyingPassRate") {
    if (value === "N/A") {
      return badgeColors.status[ScreenerLocationStatus.Paused];
    }
    return Number(value) >= 75
      ? badgeColors.qualifyingPassRate.high
      : badgeColors.qualifyingPassRate.low;
  }

  if (type.startsWith("isVisibleOnPdf")) {
    return badgeColors.isVisibleOnPdf.default;
  }

  return badgeColors[type]?.[value] || {};
};

export const rowColorCodes = {
  red: "#ffd6d4",
  orange: "#ffedd6",
  yellow: "#ffffdc",
  green: "#d6e8d2",
  blue: "#ceceff",
  purple: "#e9d1e7",
};
export const borderColorCodes = {
  red: "#ff0000",
  orange: "#ffa500",
  yellow: "#ffff00",
  green: "#008000",
  blue: "#0000ff",
  purple: "#800080",
};

export const getJobNameWithDisplayName = (
  jobName: string,
  internalName?: string,
) => `${jobName}${internalName ? ` | ${internalName}` : ""}`;
