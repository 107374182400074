import { Text } from "@mantine/core";
import type { CellContext } from "@tanstack/react-table";
import Link from "next/link";

import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import type { ScreenerLocation } from "@/types/screeners";
import { locationHasCompletedSessions } from "@/utils/helpers";

interface JobNameCellProps {
  info: CellContext<ScreenerLocation, string>;
}

export const JobNameCell = ({ info }: JobNameCellProps) => {
  const row = info.row.original;
  const track = useTrackAnalytics();

  // Avoid linking to locations without any screener sessions as they will 404.
  if (locationHasCompletedSessions(row)) {
    return (
      <Link
        href={row.positionLink}
        className="block text-[#784DD6] font-semibold w-full"
        onClick={(e) => {
          e.stopPropagation();
          track("Position Clicked", {
            screener_location_id: row.id,
          });
        }}
      >
        {info.getValue()}
      </Link>
    );
  }

  return (
    <Text fw={600} size="sm">
      {info.getValue()}
    </Text>
  );
};
