import { type ScreenerLocation } from "@/types/screeners";

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, "");
};

export const locationHasCompletedSessions = (location?: ScreenerLocation) =>
  (location?.sessionCount ?? 0) > 0;

export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) return str;
  return str.slice(0, maxLength) + "...";
};
