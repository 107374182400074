import { Text } from "@mantine/core";
import { DateInput, type DateValue } from "@mantine/dates";
import { type ColumnFiltersState } from "@tanstack/react-table";
import { type Dispatch, type SetStateAction } from "react";

import { useFilterAnalytics } from "@/hooks/analytics.hooks";
import { type Filter, useFilterState } from "@/hooks/use-filter-state.hook";
import { type Nullable } from "@/types/utils";

const COLUMN_NAME = "createdAt" as const;

export const DateFilter = ({
  name,
  filterName,
  filters,
  setFilters,
}: {
  name: string;
  filterName: "before" | "after";
  filters: ColumnFiltersState;
  setFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
}) => {
  const trackFilter = useFilterAnalytics(filterName);
  const [filter, updateFilter] = useFilterState<Nullable<number>>(
    filters as Filter[],
    setFilters,
    COLUMN_NAME,
    filterName,
  );

  const handleFilterChange = (value: DateValue) => {
    if (value) {
      trackFilter(value);
    }

    updateFilter(value ? value.valueOf() : value);
  };

  return (
    <div className="w-full my-2 flex flex-col gap-2">
      <Text fw={500} size="sm">
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </Text>
      <DateInput
        clearable
        placeholder="Select date"
        value={filter ? new Date(filter) : null}
        onChange={handleFilterChange}
        style={{ width: "100%" }}
      />
    </div>
  );
};
