"use client";

import { ActionIcon, Image, Text } from "@mantine/core";

import Link from "next/link";
import { closeExtension } from "@/utils/extension";
import { useUserContext } from "@/context/user.context";

export const Header = () => {
  const { user } = useUserContext();

  return (
    <header className="w-full flex justify-between items-center py-4 px-8 bg-white border-b-2 border-gray-2000 z-40">
      <Link href="/">
        <Image src="/assets/logo.svg" alt="Logo" h={20} />
      </Link>
      <div className="flex gap-6 items-center">
        <Text size="sm" fw={600}>
          {user?.company.name || ""}
        </Text>
        <ActionIcon
          variant="transparent"
          onClick={() => closeExtension()}
          aria-label="close"
        >
          <Image src="/assets/circle-x.svg" alt="Logo" h={20} />
        </ActionIcon>
      </div>
    </header>
  );
};
