import {
  useMutation,
  useQueryClient,
  type UseQueryOptions,
} from "@tanstack/react-query";

import {
  getCandidateScreenings,
  getScreenerLocation,
  highlightScreenerSession,
  reprocessScreenerSession,
} from "@/app/api/screeners";
import { useAuthedQuery } from "@/hooks/queries/use-authed-query.hook";
import type { ScreenerResponse } from "@/types/screeners";
import type { Nullable } from "@/types/utils";

const SCREENINGS_QUERY_KEY_BASE = "screening";
export const SCREENINGS_QUERY_KEYS = {
  base: [SCREENINGS_QUERY_KEY_BASE] as const,
  candidate: (email: string, sessionId?: string) => [
    ...SCREENINGS_QUERY_KEYS.base,
    "candidate",
    email,
    sessionId,
  ],
  location: (locationId: string) => [
    ...SCREENINGS_QUERY_KEYS.base,
    "screener_location",
    locationId,
  ],
};

export const useScreeningsByLocationQuery = (
  screenerLocationId: string,
  config: Partial<UseQueryOptions<ScreenerResponse>> = {},
) => {
  return useAuthedQuery<ScreenerResponse>({
    queryFn: () => getScreenerLocation(screenerLocationId),
    queryKey: SCREENINGS_QUERY_KEYS.location(screenerLocationId),
    ...config,
  });
};

export const useCandidateScreeningsQuery = (
  email = "",
  sessionId = "",
  config: Partial<UseQueryOptions<ScreenerResponse>> = {},
) => {
  return useAuthedQuery<ScreenerResponse>({
    queryFn: () => getCandidateScreenings(email, sessionId),
    queryKey: SCREENINGS_QUERY_KEYS.candidate(email, sessionId),
    ...config,
    enabled: !!email && config.enabled,
  });
};

export const useHighlightScreenerSessionQuery = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({ id, value }: { id: string; value: Nullable<string> }) =>
      highlightScreenerSession(id, value),
    onSuccess: () =>
      client.invalidateQueries({ queryKey: SCREENINGS_QUERY_KEYS.base }),
  });
};

export const useReprocessScreenerSessionQuery = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (sessionId: string) => reprocessScreenerSession(sessionId),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: SCREENINGS_QUERY_KEYS.base });
    },
  });
};
