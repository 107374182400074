export const FILTER_VERSION = "3";
const FILTER_VERSION_LOCAL_STORAGE_BASE_KEY = "SCREENER_FILTERS_VERSION";
export const DATE_RANGE_LOCAL_STORAGE_BASE_KEY = "SCREENER_TABLE_DATE_RANGE";
export const FILTERS_LOCAL_STORAGE_BASE_KEY = "SCREENER_TABLE_FILTERS";
export const SEARCH_LOCAL_STORAGE_BASE_KEY = "SCREENER_TABLE_SEARCH";
export const SORTING_LOCAL_STORAGE_BASE_KEY = "SCREENER_TABLE_SORTING";
const LOCAL_STORAGE_TABLE_KEYS = [
  DATE_RANGE_LOCAL_STORAGE_BASE_KEY,
  FILTERS_LOCAL_STORAGE_BASE_KEY,
  SEARCH_LOCAL_STORAGE_BASE_KEY,
  SORTING_LOCAL_STORAGE_BASE_KEY,
] as const;

export const makeLocalStorageKeyByUser = (key: string, userId = "") =>
  `${key}_${userId}`;

export const filterVersionLocalStorageKey = (userId: string) =>
  makeLocalStorageKeyByUser(FILTER_VERSION_LOCAL_STORAGE_BASE_KEY, userId);

export const clearLocalStorageTableState = (userId: string) =>
  LOCAL_STORAGE_TABLE_KEYS.forEach((key) =>
    localStorage.removeItem(makeLocalStorageKeyByUser(key, userId)),
  );
