import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons/faCircleQuestion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Tooltip } from "@mantine/core";
import { type ColumnFiltersState } from "@tanstack/react-table";
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useMemo,
} from "react";

import { useFilterAnalytics } from "@/hooks/analytics.hooks";
import {
  type Filter,
  handleSimpleArrayFilterChange,
  useFilterState,
} from "@/hooks/use-filter-state.hook";
import { type Maybe } from "@/types/utils";
import { camelize } from "@/utils/helpers";

export interface CheckBoxFilterProps<T> {
  analyticsName: string;
  name: string;
  options?: CheckBoxOption<T>[];
  filters: ColumnFiltersState;
  setFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  subFilter?: string;
}

interface CheckBoxOption<T> {
  display: string;
  helperText?: string;
  value: T;
}

export const convertStringArrayToCheckboxOption = (
  arr: string[],
): CheckBoxOption<string>[] =>
  arr
    .filter((option) => option !== "")
    .map((value) => ({
      display: value,
      value: value.toLowerCase(),
    }));

export const CheckboxFilter = <T,>({
  analyticsName,
  name,
  options = [],
  filters,
  setFilters,
  subFilter,
}: CheckBoxFilterProps<T>) => {
  const trackFilter = useFilterAnalytics(analyticsName);

  const [filterValue, updateFilter] = useFilterState<Maybe<T[]>>(
    filters as Filter[],
    setFilters,
    camelize(name),
    subFilter,
  );

  const handleFilterChange = useMemo(
    () =>
      handleSimpleArrayFilterChange<T>(updateFilter, filterValue, trackFilter),
    [filterValue, trackFilter, updateFilter],
  );

  const getLabel = useCallback((display: string, helperText?: string) => {
    if (!helperText) {
      return display;
    }

    return (
      <>
        {display}
        <Tooltip
          className="text-center"
          events={{ hover: true, focus: true, touch: true }}
          label={helperText}
          multiline
          w={220}
        >
          <button className="bg-transparent border-0 ml-1.5">
            <FontAwesomeIcon color="#CCCCCC" icon={faCircleQuestion} />
          </button>
        </Tooltip>
      </>
    );
  }, []);

  return (
    <div className="flex flex-col items-start justify-between">
      {options?.map(({ display, helperText, value }) => {
        return (
          <Checkbox
            key={display}
            label={getLabel(display, helperText)}
            classNames={{ root: "my-1" }}
            color="#724fcf"
            onChange={() => handleFilterChange(value)}
            checked={filterValue?.includes(value) ?? false}
            size="sm"
          />
        );
      })}
    </div>
  );
};
