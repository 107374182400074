"use client";

import { faArrowRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Text } from "@mantine/core";
import { ColumnFiltersState } from "@tanstack/react-table";
import Link from "next/link";
import { useCallback, useMemo } from "react";

import { getSessionCsv } from "@/app/api/screeners";
import AccountMenu from "@/components/accountMenu";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import type { Screener } from "@/types/screeners";
import { getJobNameWithDisplayName } from "@/utils/ui";

const TableHeader = ({
  filters,
  screener,
  screenerLocationId,
}: {
  filters?: ColumnFiltersState;
  screener?: Screener;
  screenerLocationId?: string;
}) => {
  const track = useTrackAnalytics();
  const page = useMemo(() => {
    if (screener) {
      return getJobNameWithDisplayName(screener.name, screener.displayName);
    }
  }, [screener]);

  const downloadCsv = useCallback(async () => {
    if (!screener || !screenerLocationId) {
      return;
    }
    track("CSV Download Clicked", { all_filters_applied: filters });

    const csvData = await getSessionCsv(screenerLocationId);
    const blob = new Blob([csvData], {
      type: "text/csv;charset=utf-8;",
    });
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute(
      "download",
      `${screener.name}-${new Date().toISOString()}.csv`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, [filters, screener, screenerLocationId, track]);

  return (
    <div className="w-full px-4 py-2 flex justify-between items-center border-b border-gray-200">
      <div className="flex">
        <Link
          href="/"
          className="text-[#784DD6] font-semibold text-xs tracking-wider uppercase"
        >
          All Screeners
        </Link>
        <Text c="#999999" className="tracking-wider" size="xs" tt="uppercase">
          {page && <FontAwesomeIcon icon={faArrowRight} className="mx-2" />}
          {page && ` ${page}`}
        </Text>
      </div>
      <div className="flex items-center">
        {page && (
          <Button
            color="#784DD6"
            className="tracking-wider uppercase"
            disabled={!screener || !screenerLocationId}
            fullWidth
            onClick={downloadCsv}
            rightSection={<FontAwesomeIcon icon={faDownload} />}
            size="xs"
            styles={{
              root: {
                display: "flex",
                justifyContent: "space-between",
                padding: "0 12px",
              },
              label: {
                flex: 1,
                textAlign: "left",
              },
            }}
            variant="transparent"
          >
            Download
          </Button>
        )}
        <AccountMenu />
      </div>
    </div>
  );
};

export default TableHeader;
